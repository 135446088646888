import React, { ReactChildren, ReactChild, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../header/header";
import Footer from "../footer/footer";
import PromoHandband from "../promoHandband/promoHandband";
import "./layout.scss";

interface AuxProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  isViewPromoOnHeader: Boolean;
  forceIsScroll: Boolean;
}

const Layout = ({
  isViewPromoOnHeader = false,
  children,
  forceIsScroll = false,
}: AuxProps) => {
  const [isPromoActive, setIsPromoActive] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number | null>(null); // Default to null

  useEffect(() => {
    // Check if the code is running in the browser
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      // Set initial value for windowWidth
      setWindowWidth(window.innerWidth);

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const isMobile = windowWidth !== null && windowWidth < 768;

  return (
    <>
      <Header
        isViewPromoOnHeader={isViewPromoOnHeader}
        forceIsScroll={forceIsScroll}
      />
      <div className={`promo-handband-wrapper ${isMobile ? "mobile" : ""}`}>
        {/* Wrap PromoHandband in a container */}
        <PromoHandband
          isPromoActive={isPromoActive}
          isViewPromoOnHeader={isViewPromoOnHeader}
          setIsPromoActive={setIsPromoActive}
          isDiagnosticPage={false}
        />
      </div>
      {/* Conditionally apply a class based on isPromoActive */}
      <main className={`page-principale ${!isPromoActive ? "no-handband" : ""}`}>
        {children}
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
